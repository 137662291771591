<template>
  <div>
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12" md="6">
        <v-card class="border-radius-lg mt-5">
          <v-row class="justify-space-between pt-3 pb-5 px-7">
            <div>
              <h5 class="text-h5 my-2 font-weight-bold text-typo">
                Kundeneinladung
              </h5>
            </div>
          </v-row>
          <v-card class="border-radius-md">
            <div v-if="invitation && invitation.serviceProvider" class="pa-3">
              <v-row class="align-center mb-3">
                <v-col class="pa-4">
                  <div class="text-md">
                    <span class="font-weight-bolder">
                      {{ `${invitation.serviceProvider.name}` }}
                    </span>
                    möchte dich als Kunde hinzufügen
                  </div>
                  <div class="text-md mt-4">
                    Wenn du annehmen möchtest, wähle eine Firma aus, mit der du
                    beitreten möchtest:
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>

              <div
                class="mb-2 bg-light pa-3 border-radius-lg"
                style="cursor: pointer; position: relative"
                v-for="company in companies.data"
                :key="company.id"
                @click="editCustomer(invitation, company)"
              >
                <v-row class="align-center">
                  <v-avatar
                    size="50"
                    class="ma-3 text-uppercase text-dark"
                    color="grey"
                  >
                    {{ company.name[0] }}{{ company.name[1] }}
                  </v-avatar>
                  <v-col>
                    <div>
                      <div class="text-dark">
                        {{ company.name }}
                      </div>
                      <div class="text-body">
                        {{ company.street }}, {{ company.zip }}
                        {{ company.city }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-btn
                @click="deleteCustomer(invitation)"
                elevation="0"
                :ripple="false"
                height="43"
                variant="outlined"
                class="font-weight-bold btn-default mt-5 bg-danger text-white py-2 px-6 me-2 mb-1 w-100"
                small
                ><span v-if="!loadingAppointments">Ablehnen</span
                ><v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-btn>
            </div>
            <div v-else>
              <v-row class="align-center mb-3">
                <v-col class="pa-4">
                  <div class="text-md px-3 text-danger">
                    Es konnte keine gültige Einladung gefunden werden.
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-card></v-col
      ></v-row
    >
    <!-- <v-dialog v-model="customerInfoModal" max-width="500px" persistent>
      <CustomerInfo :customer="selectedCustomer" @close="closeCustomerModal()" />
    </v-dialog> -->
  </div>
</template>

<script>
import axiosAuth from "@/shared/configs/axios-auth";
import swal from "sweetalert2";

export default {
  name: "KundenEinladung",
  data() {
    return {
      invitation: null,
    };
  },
  mounted() {
    this.getInvitation();
  },
  methods: {
    getInvitation() {
      axiosAuth
        .get("/customers/" + this.$route?.query?.invitationcode, {
          headers: {
            version: "v1.1",
          },
        })
        .then((res) => {
          if (res.data.accepted == null && !res.data.customerId) {
            if (res.data.placeholder == this.userInformations.email) {
              this.invitation = res.data;
            } else {
              this.invitation = null;
            }
          } else {
            this.invitation = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editCustomer(customer, company) {
      let vm = this;
      axiosAuth
        .patch("/customers/" + this.$route?.query?.invitationcode, {
          accepted: true,
          customerId: company.id,
          placeholder: null,
        })
        .then((res) => {
          vm.getInvitation();
          swal.fire(
            "Erfolg!",
            `Du bist mit ${company.name} beigetreten.`,
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
          this.getInvitation();
          swal.fire(
            "Fehler bei der Anfrage!",
            "Die Anfrage konnte nicht bearbeitet werden.",
            "error"
          );
        });
    },
    deleteCustomer(customer) {
      let vm = this;
      axiosAuth
        .patch("/customers/" + customer.id, { accepted: false })
        .then((res) => {
          vm.getInvitation();
          swal.fire(
            "Abgelehnt!",
            `Du bist ${customer.serviceProvider.name} NICHT beigetreten.`,
            "warning"
          );
        })
        .catch((error) => {
          console.log(error);
          this.getInvitation();
          swal.fire(
            "Fehler bei der Anfrage!",
            "Die Anfrage konnte nicht bearbeitet werden.",
            "error"
          );
        });
    },
  },
};
</script>
